import { TBrand } from '@agco-onedigital/ecosystem-lib-brand-styles';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ERegistrationStep,
  IRegistrationAddress,
  IRegistrationDetails,
  IRegistrationPhoneNumbers,
  ISearchAddress,
  TRegistrationState,
} from '@agco-onedigital/ecosystem-lib-frontend-utils';

import { getPhoneErrorKey } from '../../pages/Registration/RegistrationSteps/Forms/PhoneForm/utils';
import { IPhoneValidationPayload } from '../User';
import {
  checkEmailAndSendOtpThunk,
  verifyActivationCode,
  verifyOtpThunk,
  registerAgcoUser,
  addressSuggestionsThunk,
} from './thunks';
import { validatePhoneThunk } from './thunks/validatePhoneThunk';

const initialState: TRegistrationState = {
  data: {
    stepsCompleted: [],
    stepsVisited: [ERegistrationStep.AgcoId],
    isFormDirty: false,
  },
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setBrand: (state, action: PayloadAction<TBrand>) => {
      state.data.brand = action.payload;
    },
    setRegistrationLocale: (state, action: PayloadAction<string>) => {
      state.data.locale = action.payload;
    },
    setRegistrationIsOpen: (state, action: PayloadAction<boolean>) => {
      state.data.isOpen = action.payload;
    },
    resetRegistrationErrors: state => {
      if (!state.data.activationCodeState?.isVerified) {
        delete state.data.activationCodeState;
      }
      delete state.data.emailError;
      delete state.data.isEmailAlreadyUsed;
      delete state.data.isLoading;
    },
    resetOtpTransactionId: state => {
      delete state.data.otpTransactionId;
      delete state.data.emailError;
      delete state.data.isEmailAlreadyUsed;
    },
    setRegistrationStep: (state, action: PayloadAction<ERegistrationStep>) => {
      state.data.currentStep = action.payload;
      if (!state.data.stepsVisited?.includes(action.payload)) {
        state.data.stepsVisited?.push(action.payload);
      }
    },
    storePassword: (state, action: PayloadAction<string>) => {
      state.data.password = action.payload;
      state.data.isFormDirty = false;
    },
    storeDetails: (state, action: PayloadAction<IRegistrationDetails>) => {
      state.data.details = action.payload;
      state.data.isFormDirty = false;
    },
    storeAddress: (state, action: PayloadAction<IRegistrationAddress>) => {
      state.data.address = action.payload;
      state.data.isFormDirty = false;
    },
    storeAddressSearch: (state, action: PayloadAction<string>) => {
      state.data.addressSearch = action.payload;
    },
    resetAddressSuggestions: state => {
      state.data.selectedAddressSuggestion = undefined;
      state.data.addressSearch = undefined;
      state.data.addressSuggestions = undefined;
    },
    storeSelectedAddressSuggestion: (state, action: PayloadAction<ISearchAddress>) => {
      state.data.selectedAddressSuggestion = action.payload;
    },
    storePhoneNumbers: (state, action: PayloadAction<IRegistrationPhoneNumbers>) => {
      state.data.phoneNumbers = action.payload;
      state.data.isFormDirty = false;
    },
    storeBusinessInformation: (state, action: PayloadAction<{ address: IRegistrationAddress; name: string }>) => {
      state.data.account = {
        name: action.payload.name,
        shippingAddress: [action.payload.address],
        billingAddress: [action.payload.address],
      };
      state.data.isFormDirty = false;
    },
    storePinPassCode: (state, action: PayloadAction<string>) => {
      state.data.pinPassCode = action.payload;
    },
    setStepCompleted: (state, action: PayloadAction<ERegistrationStep>) => {
      if (!state.data.stepsCompleted?.includes(action.payload)) {
        state.data.stepsCompleted?.push(action.payload);
      }
    },
    setFormDirty: (state, action: PayloadAction<boolean>) => {
      state.data.isFormDirty = action.payload;
    },
    resetAddressSearchError: state => {
      delete state.data.addressSearchError;
    },
    resetPhoneErrors: state => {
      delete state.data.isValidMobilePhone;
      delete state.data.mobilePhoneErrorMessage;
      delete state.data.isValidPhone;
      delete state.data.phoneErrorMessage;
    },
  },
  extraReducers: builder => {
    builder.addCase(verifyActivationCode.fulfilled, (state, action) => {
      const { activationCode, valid = null } = action.payload;
      state.data.activationCodeState = {
        isVerified: valid,
        activationCode,
        loading: valid ? 'succeeded' : 'failed',
      };
    });
    builder.addCase(verifyActivationCode.pending, state => {
      state.data.activationCodeState = {
        isVerified: false,
        loading: 'pending',
      };
    });

    builder.addCase(checkEmailAndSendOtpThunk.pending, state => {
      state.data.isEmailAlreadyUsed = false;
      state.data.isLoading = true;
      state.data.emailError = false;
    });
    builder.addCase(checkEmailAndSendOtpThunk.fulfilled, (state, action) => {
      const { isEmailAlreadyUsed, emailError, otpTransactionId, email, isBusinessOwner } = action.payload;
      state.data = {
        ...state.data,
        isEmailAlreadyUsed,
        isBusinessOwner: isBusinessOwner || false,
        emailError,
        otpTransactionId,
        email,
        isLoading: false,
        ...(!!state.data.otpTransactionId && { otpResent: true }),
      };
    });

    builder.addCase(verifyOtpThunk.pending, state => {
      state.data.emailError = false;
      state.data.isLoading = true;
    });
    builder.addCase(verifyOtpThunk.fulfilled, (state, action) => {
      const { emailError, transactionId } = action.payload;
      state.data = {
        ...state.data,
        isLoading: false,
        emailError,
        ...(!!transactionId && {
          otpTransactionId: transactionId,
          currentStep: ERegistrationStep.Password,
        }),
      };
      if (!state.data.stepsVisited?.includes(ERegistrationStep.Password)) {
        state.data.stepsVisited?.push(ERegistrationStep.Password);
      }
    });
    builder.addCase(registerAgcoUser.pending, state => {
      state.data.registerUserRequestState = {
        loading: 'pending',
      };
    });
    builder.addCase(registerAgcoUser.fulfilled, state => {
      state.data.registerUserRequestState = {
        loading: 'succeeded',
      };
    });
    builder.addCase(registerAgcoUser.rejected, (state, action) => {
      state.data.registerUserRequestState = {
        loading: 'failed',
        error: action.error.message,
      };
    });
    builder.addCase(addressSuggestionsThunk.fulfilled, (state, action) => {
      if (action.payload === null) {
        console.log('Permission denied');
        state.data.addressSuggestions = undefined;
        state.data.addressSearchError = 'API_ERROR';
      } else {
        state.data.addressSuggestions = action.payload;
        if (state.data.addressSuggestions.length === 0) {
          state.data.addressSearchError = 'NO_RESULTS';
        }
      }
    });

    builder.addCase(addressSuggestionsThunk.pending, state => {
      state.data.addressSearchError = undefined;
      state.data.addressSuggestions = [];
    });

    builder.addCase(addressSuggestionsThunk.rejected, state => {
      state.data.addressSuggestions = [];
      state.data.addressSearchError = 'API_ERROR';
    });
    builder.addCase(validatePhoneThunk.fulfilled, (state, action) => {
      if (state.data) {
        state.data.isPhoneValidationLoading = false;
      }

      if (action.payload.phoneType === 'mobile') {
        state.data.isValidMobilePhone = action.payload.isValid;
        state.data.mobilePhoneErrorMessage = undefined;
      } else {
        state.data.isValidPhone = action.payload.isValid;
        state.data.phoneErrorMessage = undefined;
      }
    });

    builder.addCase(validatePhoneThunk.rejected, (state, action) => {
      if (state.data) {
        state.data.isPhoneValidationLoading = false;
      }

      const { payload } = action as { payload: IPhoneValidationPayload };

      if (payload.phoneType === 'mobile') {
        state.data.isValidMobilePhone = false;
        state.data.mobilePhoneErrorMessage = getPhoneErrorKey(payload.message);
      } else {
        state.data.isValidPhone = false;
        state.data.phoneErrorMessage = getPhoneErrorKey(payload.message);
      }
    });

    builder.addCase(validatePhoneThunk.pending, (state, action) => {
      if (state.data) {
        state.data.isPhoneValidationLoading = true;
      }
      const { phoneType } = action.meta.arg;

      if (phoneType === 'mobile') {
        state.data.mobilePhoneErrorMessage = undefined;
      } else {
        state.data.phoneErrorMessage = undefined;
      }
      state.data.isPhoneValidationLoading = true;
    });
  },
});

export const {
  setBrand,
  setRegistrationLocale,
  setRegistrationIsOpen,
  setRegistrationStep,
  storePassword,
  storeDetails,
  storeAddress,
  storeAddressSearch,
  storeSelectedAddressSuggestion,
  resetAddressSuggestions,
  storeBusinessInformation,
  storePinPassCode,
  storePhoneNumbers,
  resetOtpTransactionId,
  resetRegistrationErrors,
  setStepCompleted,
  setFormDirty,
  resetAddressSearchError,
  resetPhoneErrors,
} = registrationSlice.actions;

const registrationReducer = registrationSlice.reducer;

export default registrationReducer;
