import { ApolloError } from '@apollo/client';
import { TLaunchDarklySet } from '../../LaunchDarkly';
import { IPhoneValidationInput, IPhoneValidationPayload, IValidatePhoneResult } from '../types';

export const validatePhoneCommon = async (
  validateFunction: (
    launchDarklyData: TLaunchDarklySet,
    input: IPhoneValidationInput,
    signal: AbortSignal,
  ) => Promise<any>,
  launchDarklyData: TLaunchDarklySet,
  phoneValidationInput: IPhoneValidationInput,
  rejectWithValue: (value: IPhoneValidationPayload) => any,
  signal: AbortSignal,
): Promise<IValidatePhoneResult | ReturnType<typeof rejectWithValue>> => {
  try {
    const response = await validateFunction(launchDarklyData, phoneValidationInput, signal);

    if (response?.errors?.length > 0) {
      const graphqlError = response.errors[0];
      const isInternalError =
        graphqlError?.extensions?.errorType === 'INTERNAL' && graphqlError?.extensions?.status === 401;

      const errorMessage = isInternalError
        ? 'phoneNumberApiError'
        : graphqlError?.extensions?.errors?.[0]?.message ?? graphqlError?.message ?? 'Validation failed';

      return rejectWithValue({
        message: errorMessage,
        phoneType: phoneValidationInput.phoneType,
      });
    }

    if (!response?.data) {
      return rejectWithValue({
        message: 'Invalid response format',
        phoneType: phoneValidationInput.phoneType,
      });
    }

    return {
      isValid: response.data.validatePhone === true,
      phoneType: phoneValidationInput.phoneType,
    };
  } catch (error) {
    if ((error as Error).name === 'AbortError') {
      return rejectWithValue({
        message: 'Request aborted',
        phoneType: phoneValidationInput.phoneType,
      });
    }

    const errorMessage = error instanceof ApolloError ? error.message : 'API_ERROR';

    return rejectWithValue({
      message: errorMessage,
      phoneType: phoneValidationInput.phoneType,
    });
  }
};
