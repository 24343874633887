import { gql } from '@apollo/client';

import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';
import { IMachineDetails } from '../../../../store/MachineDetails';

import { TQueryResponse } from '../../../types';

import { client } from '../../graphql';

export const getFleetDevice = async (launchDarkly: TLaunchDarklySet, fleetDeviceId: string) => {
  const imagesEnabled = launchDarkly['machine-image-upload'];
  const notesEnabled = launchDarkly['machine-notes'];
  const { data } = await client(launchDarkly).query<TQueryResponse<'fleetDevice', IMachineDetails>>({
    query: gql`
      query FleetDevice($fleetDeviceId: ID!) {
        fleetDevice(fleetDeviceId: $fleetDeviceId) {
          id
          type
          account {
            id
            name
          }
          ${
            notesEnabled
              ? `notes {
            id
            title
            description
            author
            updateDateTime
          }`
              : ''
          }
          brand {
            id
            name
            isAgco
          }
          brandText
          subCategory {
            defaultDisplayName
            category {
              defaultDisplayName
            }
          }
          modelSeries {
            name
          }
          modelSeriesText
          model {
            name
            id
            modelSeries {
              name
            }
            ${
              imagesEnabled
                ? `images {
              url
              thumbnails {
                SIZE_319_319
                SIZE_48_48
                SIZE_140_100
              }
            }`
                : ''
            }
          }
          categoryText
          subCategoryText
          modelText
          image
          modelVariant {
            name
            id
            ${
              imagesEnabled
                ? `images {
              url
              thumbnails {
                SIZE_319_319
                SIZE_48_48
                SIZE_140_100
              }
            }`
                : ''
            }
          }
          ${
            imagesEnabled
              ? `images {
            url
            thumbnails {
              SIZE_319_319
              SIZE_48_48
              SIZE_140_100
            }
          }`
              : ''
          }
          modelVariantText
          name
          vin
          ownershipStartDate
          ownershipStatus
          yearOfManufacture
          condition
        }
      }
    `,
    variables: { fleetDeviceId },
  });
  return data.fleetDevice;
};
