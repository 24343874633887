import { TQueryResponse } from '@agco-onedigital/ecosystem-lib-frontend-utils';
import { gql } from '@apollo/client';
import { TLaunchDarklySet } from '../../../store/LaunchDarkly';
import { IMachineNote } from '../../../store/MachineDetails';
import { client } from '../graphql';

export interface ICreateMachineNoteInput {
  title: string;
  description: string;
  deviceId: string;
}

export const createMachineNote = async (launchDarkly: TLaunchDarklySet, noteData: ICreateMachineNoteInput) => {
  const mutation = gql`
    mutation CreateNote($noteCreateInput: NoteCreateInput!) {
      createNote(noteCreateInput: $noteCreateInput) {
        id
        title
        description
        author
        updateDateTime
      }
    }
  `;

  const result = await client(launchDarkly).mutate<TQueryResponse<'createNote', IMachineNote>, unknown>({
    mutation,
    variables: {
      noteCreateInput: {
        title: noteData.title.replace(/\s+/g, ' ').trim(),
        description: noteData.description.trim(),
        deviceId: noteData.deviceId,
      },
    },
  });

  if (!result.data) {
    throw new Error('No data returned from server');
  }

  return result.data.createNote;
};
