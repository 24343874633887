import { TQueryResponse } from '@agco-onedigital/ecosystem-lib-frontend-utils';
import { gql } from '@apollo/client';
import { TLaunchDarklySet } from '../../../store/LaunchDarkly';
import { IMachineNote } from '../../../store/MachineDetails';
import { client } from '../graphql';

export interface IUpdateMachineNoteInput {
  deviceId: string;
  noteId: string;
  title: string;
  description: string;
}

export const updateMachineNote = async (launchDarkly: TLaunchDarklySet, noteData: IUpdateMachineNoteInput) => {
  const mutation = gql`
    mutation UpdateNote($noteUpdateInput: NoteUpdateInput!) {
      updateNote(noteUpdateInput: $noteUpdateInput) {
        id
        title
        description
        author
        updateDateTime
      }
    }
  `;

  const result = await client(launchDarkly).mutate<TQueryResponse<'updateNote', IMachineNote>, unknown>({
    mutation,
    variables: {
      noteUpdateInput: {
        noteId: noteData.noteId,
        title: noteData.title.replace(/\s+/g, ' ').trim(),
        description: noteData.description.trim(),
      },
    },
  });

  if (!result.data) {
    throw new Error('No data returned from server');
  }

  return result.data.updateNote;
};
