import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkConfig } from '../../store';
import { deleteMachineNote, IDeleteNoteResponse } from '../../../service/graphql/machine-notes/deleteMachineNote';
import { getMachineThunk } from './getMachineThunk';

interface IDeleteMachineNoteInput {
  noteId: string;
  deviceId: string;
}

export const deleteMachineNoteThunk = createAsyncThunk<IDeleteNoteResponse, IDeleteMachineNoteInput, AsyncThunkConfig>(
  'machineNote/deleteNote',
  async (noteData, { getState, dispatch }) => {
    const {
      launchDarkly,
      selectedAccount: { activeAccountId },
    } = getState();

    if (!activeAccountId) {
      throw new Error('No accounts or user found.');
    }

    const result = await deleteMachineNote(launchDarkly.data, noteData.noteId);
    await dispatch(getMachineThunk(noteData.deviceId));
    return result;
  },
);
