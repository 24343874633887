import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISearchAddress } from '@agco-onedigital/ecosystem-lib-frontend-utils';

import { addressSuggestions } from '../../../service/graphql/user/addressSuggestions';
import { registrationClient } from '../../../service/graphql/registration/registrationClient';
import { AsyncThunkConfig } from '../../store';

export const addressSuggestionsThunk = createAsyncThunk<
  ISearchAddress[],
  { addressLine: string; countryCode: string },
  AsyncThunkConfig
>('user/addressSuggestions', async (addressInput, { getState }) => {
  const { launchDarkly } = getState();

  const client = registrationClient(launchDarkly.data);

  return addressSuggestions(client, launchDarkly.data, {
    addressLine: addressInput.addressLine,
    countryCode: addressInput.countryCode,
  });
});
