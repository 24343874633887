import { gql } from '@apollo/client';
import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';
import { IPhoneValidationInput } from '../../../../store/User';
import { client } from '../../graphql';

export const validatePhonePostLogin = async (
  launchDarkly: TLaunchDarklySet,
  phoneValidationInput: IPhoneValidationInput,
) => {
  const response = await client(launchDarkly).query({
    query: gql`
      query ($phoneNumber: String!, $countryCode: String!) {
        validatePhone(phoneValidationInput: { phoneNumber: $phoneNumber, countryCode: $countryCode })
      }
    `,
    variables: {
      phoneNumber: phoneValidationInput.phoneNumber,
      countryCode: phoneValidationInput.countryCode,
    },
  });

  return response;
};
