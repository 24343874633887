import { Typography } from '@agco-onedigital/ecosystem-lib-web-components';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: url('/images/site-under-maintenance/background.jpg');
  background-position: top center;
  background-size: cover;
  min-height: 100vh;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const FarmerCore = styled.img`
  height: 29px;
  top: 50px;
  right: 50px;
  position: absolute;
  pointer-events: none;
  ${p =>
    p.theme.mobileSm.max(css`
      width: calc(100% - 20px);
      right: auto;
      left: 10px;
    `)}
`;

export const MaintenanceWrapper = styled.div`
  background: white;
  opacity: 0.85;
  box-sizing: border-box;
  padding: 80px 140px;
  text-align: center;
  max-width: 860px;
  margin: 0 auto;
  position: relative;
  ${p =>
    p.theme.mobileLg.max(css`
      padding: 32px;
    `)}
  margin-bottom: 50px;
`;

export const MaintenanceIcon = styled.div`
  color: ${p => p.theme.colors.text.redShade800};

  span {
    font-size: 140px !important;
  }
`;

export const MaintenanceDescription = styled(Typography.Body)`
  color: ${p => p.theme.colors.text.darkError};
  text-align: left;
`;
