import { createAsyncThunk } from '@reduxjs/toolkit';
import { validatePhone } from '../../../service/graphql/registration/validatePhone';
import { IValidatePhoneResult, IPhoneValidationInput } from '../../User';
import { AsyncThunkConfig } from '../../store';
import { validatePhoneCommon } from '../../User/thunks/validatePhoneCommonThunk';

export const validatePhoneThunk = createAsyncThunk<
  IValidatePhoneResult,
  IPhoneValidationInput & { signal: AbortSignal },
  AsyncThunkConfig
>('user/validatePhone', async (phoneValidationInput, { getState, rejectWithValue }) => {
  const { launchDarkly } = getState();

  try {
    const response = await validatePhoneCommon(
      validatePhone,
      launchDarkly.data,
      phoneValidationInput,
      rejectWithValue,
      phoneValidationInput.signal,
    );

    return response;
  } catch (error) {
    if ((error as Error).name === 'AbortError') {
      return rejectWithValue('Request aborted');
    }
    throw error;
  }
});
