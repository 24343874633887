import { AppDispatch } from '../../../../../store';
import { IPhoneValidationInput } from '../../../../../store/User';
import debounce from '../../../../../utils/debounce';
import { MINIMUM_PHONE_LENGTH } from './consts';
import { PhoneErrorKey, PhoneValidationParams } from './types';

export const cleanPhoneNumber = (phoneNumber: string, countryDial?: string) => {
  return phoneNumber.replace(countryDial ?? '', '').replace(/\s+/g, '');
};

export const createDebouncedPhoneValidation = (
  dispatch: AppDispatch,
  validatePhoneThunk: (input: IPhoneValidationInput & { signal: AbortSignal }) => any,
  setMobileValidated?: (value: boolean) => void,
  setOfficeValidated?: (value: boolean) => void,
) => {
  let abortController: AbortController | null = null;

  return debounce(
    async ({
      value,
      countryDial,
      countryCode,
      phoneType,
      phoneValidationEnabled,
    }: Omit<PhoneValidationParams, 'dispatch'>) => {
      if (abortController) {
        abortController.abort();
      }

      abortController = new AbortController();

      const cleanNumber = cleanPhoneNumber(value, countryDial);

      if (phoneValidationEnabled && cleanNumber.length > MINIMUM_PHONE_LENGTH && countryCode) {
        try {
          const result = await dispatch(
            validatePhoneThunk({
              phoneNumber: cleanNumber,
              countryCode,
              phoneType,
              signal: abortController.signal,
            }),
          );

          if (phoneType === 'mobile') {
            setMobileValidated?.(!result?.meta?.rejectedWithValue);
          } else if (phoneType === 'office') {
            setOfficeValidated?.(!result?.meta?.rejectedWithValue);
          }
        } catch (error) {
          if (phoneType === 'mobile') {
            setMobileValidated?.(false);
          } else if (phoneType === 'office') {
            setOfficeValidated?.(false);
          }
          console.error('Phone validation failed:', error);
        }
      }
    },
    800,
  );
};

export const getPhoneErrorKey = (message?: string): PhoneErrorKey =>
  message?.includes('Invalid number') ? 'phoneNumberError' : 'phoneNumberApiError';
