import { createAsyncThunk } from '@reduxjs/toolkit';
import { validatePhonePostLogin } from '../../../service/graphql/user/validatePhonePostLogin';
import { AsyncThunkConfig } from '../../store';
import { IPhoneValidationInput, IValidatePhoneResult } from '..';
import { validatePhoneCommon } from './validatePhoneCommonThunk';

export const validatePhonePostLoginThunk = createAsyncThunk<
  IValidatePhoneResult,
  IPhoneValidationInput & { signal: AbortSignal },
  AsyncThunkConfig
>('user/validatePhonePostLogin', async (phoneValidationInput, { getState, rejectWithValue }) => {
  const { launchDarkly } = getState();

  try {
    const response = await validatePhoneCommon(
      validatePhonePostLogin,
      launchDarkly.data,
      phoneValidationInput,
      rejectWithValue,
      phoneValidationInput.signal,
    );

    return response;
  } catch (error) {
    if ((error as Error).name === 'AbortError') {
      return rejectWithValue('Request aborted');
    }
    throw error;
  }
});
