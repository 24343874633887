import { gql } from '@apollo/client';
import { TQueryResponse } from '@agco-onedigital/ecosystem-lib-frontend-utils';
import { TLaunchDarklySet } from '../../../store/LaunchDarkly';
import { client } from '../graphql';

export interface IDeleteNoteResponse {
  status: string;
  message: string;
}

export const deleteMachineNote = async (launchDarkly: TLaunchDarklySet, noteId: string) => {
  const mutation = gql`
    mutation DeleteNote($noteId: ID!) {
      deleteNote(noteId: $noteId) {
        status
        message
      }
    }
  `;

  const result = await client(launchDarkly).mutate<TQueryResponse<'deleteNote', IDeleteNoteResponse>, unknown>({
    mutation,
    variables: {
      noteId,
    },
  });

  if (!result.data) {
    throw new Error('No data returned from server');
  }

  return result.data.deleteNote;
};
