import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkConfig } from '../../store';
import { IMachineNote } from '../types';
import { IUpdateMachineNoteInput, updateMachineNote } from '../../../service/graphql/machine-notes/updateMachineNote';
import { getMachineThunk } from './getMachineThunk';

export const updateMachineNoteThunk = createAsyncThunk<IMachineNote, IUpdateMachineNoteInput, AsyncThunkConfig>(
  'machineNote/updateNote',
  async (noteData, { getState, dispatch }) => {
    const {
      launchDarkly,
      selectedAccount: { activeAccountId },
    } = getState();

    if (!activeAccountId) {
      throw new Error('No accounts or user found.');
    }

    const result = await updateMachineNote(launchDarkly.data, noteData);
    await dispatch(getMachineThunk(noteData.deviceId));
    return result;
  },
);
