import React from 'react';
import { useIntl } from 'react-intl';
import { commonMessages } from '@agco-onedigital/ecosystem-lib-frontend-utils';

import { Helmet } from 'react-helmet';
import { MaterialIcon, Typography } from '@agco-onedigital/ecosystem-lib-web-components';
import { useFireEventOnPageLoad } from '../../utils/hooks/useFireEventOnPageLoad';

import { Container, FarmerCore, MaintenanceDescription, MaintenanceIcon, MaintenanceWrapper } from './styles';

const messages = commonMessages.general;

const SiteUnderMaintenance = () => {
  const { formatMessage } = useIntl();

  useFireEventOnPageLoad('SiteUnderMaintenance');

  return (
    <Container>
      <Helmet>
        <title>{formatMessage(messages.siteUnderMaintenance)}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <FarmerCore src="/images/home/farmer-core.svg" />
      <MaintenanceWrapper>
        <MaintenanceIcon>
          <MaterialIcon name="browser_updated" />
        </MaintenanceIcon>
        <Typography.H3>{formatMessage(messages.siteUnderMaintenance)}</Typography.H3>
        <MaintenanceDescription size="sm">
          {formatMessage(messages.siteUnderMaintenanceDescription)}
        </MaintenanceDescription>
      </MaintenanceWrapper>
    </Container>
  );
};

export default SiteUnderMaintenance;
