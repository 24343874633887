import { useEffect, useRef } from 'react';
import { useUser } from '../../store/User/selectors';

export const useFireEventOnPageLoad = (module: string) => {
  const didRun = useRef(false);
  const user = useUser();

  let region = '';
  for (const item of user?.data?.accounts ?? []) {
    if (item.account?.region) {
      region = item.account?.region;
      break;
    }
  }

  useEffect(() => {
    if (!didRun.current) {
      window?.dataLayer?.push({
        event: 'page_load',
        Module: module,
        country_level: user.data?.country ?? '',
        region_level: region,
        language: user.data?.locale,
      });
      didRun.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
