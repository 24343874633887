import { gql } from '@apollo/client';
import { TLaunchDarklySet } from '../../../../store/LaunchDarkly';
import { IPhoneValidationInput } from '../../../../store/User';
import { registrationClient } from '../registrationClient';

export const validatePhone = async (launchDarkly: TLaunchDarklySet, phoneValidationInput: IPhoneValidationInput) => {
  const client = registrationClient(launchDarkly);
  const response = await client.query({
    query: gql`
      query ($phoneNumber: String!, $countryCode: String!) {
        validatePhone(phoneValidationInput: { phoneNumber: $phoneNumber, countryCode: $countryCode })
      }
    `,
    variables: {
      phoneNumber: phoneValidationInput.phoneNumber,
      countryCode: phoneValidationInput.countryCode,
    },
  });

  return response;
};
