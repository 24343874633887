import { createSlice } from '@reduxjs/toolkit';
import { TUserState, commonMessages } from '@agco-onedigital/ecosystem-lib-frontend-utils';
import { Snackbar } from '@agco-onedigital/ecosystem-lib-web-components';
import { getPhoneErrorKey } from '../../pages/Registration/RegistrationSteps/Forms/PhoneForm/utils';
import { getUserThunk, saveUserThunk, updateAccountThunk } from './thunks';
import { addressSuggestionsPostLoginThunk } from './thunks/addressSuggestionsPostLoginThunk';
import { validatePhonePostLoginThunk } from './thunks/validatePhonePostLoginThunk';
import { IPhoneValidationPayload } from './types';

const messages = commonMessages.general;

export const initialState: TUserState = {
  isSaving: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetPostLoginAddressSuggestions: state => {
      if (state.data) {
        state.data.addressSuggestions = undefined;
      }
    },
    resetAddressSearchErrorUser: state => {
      if (state.data) {
        state.data.addressSearchError = undefined;
      }
    },
    resetPhoneErrorMessages: state => {
      if (state.data) {
        state.data.phoneErrorMessage = undefined;
        state.data.mobilePhoneErrorMessage = undefined;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getUserThunk.rejected, (state, err) => {
      state.isFailedToLoad = true;
      state.isLoading = false;
    });

    builder.addCase(getUserThunk.pending, state => {
      state.isLoading = true;
      state.isFailedToLoad = false;
    });

    builder.addCase(getUserThunk.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });

    builder.addCase(saveUserThunk.pending, state => {
      state.isSaving = true;
      state.isFailedToSave = false;
    });
    builder.addCase(saveUserThunk.rejected, state => {
      state.isSaving = false;
      state.isFailedToSave = true;
      Snackbar.showSnack({
        title: messages.apiError,
        type: 'error',
      });
    });
    builder.addCase(saveUserThunk.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isSaving = false;
    });
    builder.addCase(updateAccountThunk.pending, state => {
      state.isSaving = true;
      state.isFailedToSave = false;
    });
    builder.addCase(updateAccountThunk.rejected, state => {
      state.isSaving = false;
      state.isFailedToSave = true;
      Snackbar.showSnack({
        title: messages.apiError,
        type: 'error',
      });
    });
    builder.addCase(updateAccountThunk.fulfilled, (state, action) => {
      if (state.data) {
        const accountIndex = state.data.accounts.findIndex(account => account.account.id === action.payload.id);
        if (accountIndex > -1) {
          state.data.accounts[accountIndex].account = {
            ...state.data.accounts[accountIndex].account,
            ...action.payload,
            businessTypes: action.payload.businessTypes || [],
          };
        }
      }
      state.isSaving = false;
    });
    builder.addCase(addressSuggestionsPostLoginThunk.fulfilled, (state, action) => {
      if (action.payload === null) {
        console.log('Permission denied');
        if (state.data) {
          state.data.addressSuggestions = undefined;
          state.data.addressSearchError = 'API_ERROR';
        }
      } else if (state.data) {
        state.data.addressSuggestions = action.payload;
        if (state.data.addressSuggestions.length === 0) {
          state.data.addressSearchError = 'NO_RESULTS';
        }
      }
    });

    builder.addCase(addressSuggestionsPostLoginThunk.pending, state => {
      if (state.data) {
        state.data.addressSearchError = undefined;
        state.data.addressSuggestions = [];
      }
    });

    builder.addCase(addressSuggestionsPostLoginThunk.rejected, state => {
      if (state.data) {
        state.data.addressSuggestions = [];
        state.data.addressSearchError = 'API_ERROR';
      }
    });
    builder.addCase(validatePhonePostLoginThunk.fulfilled, (state, action) => {
      state.data!.isPhoneValidationLoading = false;

      if (action.payload.phoneType === 'mobile') {
        state.data!.isValidMobilePhone = action.payload.isValid;
        state.data!.mobilePhoneErrorMessage = undefined;
      } else {
        state.data!.isValidPhone = action.payload.isValid;
        state.data!.phoneErrorMessage = undefined;
      }
    });

    builder.addCase(validatePhonePostLoginThunk.rejected, (state, action) => {
      state.data!.isPhoneValidationLoading = false;

      const { payload } = action as { payload: IPhoneValidationPayload };

      if (payload.phoneType === 'mobile') {
        state.data!.isValidMobilePhone = false;
        state.data!.mobilePhoneErrorMessage = getPhoneErrorKey(payload.message);
      } else {
        state.data!.isValidPhone = false;
        state.data!.phoneErrorMessage = getPhoneErrorKey(payload.message);
      }
    });

    builder.addCase(validatePhonePostLoginThunk.pending, (state, action) => {
      const { phoneType } = action.meta.arg;

      if (phoneType === 'mobile') {
        state.data!.mobilePhoneErrorMessage = undefined;
      } else {
        state.data!.phoneErrorMessage = undefined;
      }
      state.data!.isPhoneValidationLoading = true;
    });
  },
});

export const { resetPostLoginAddressSuggestions, resetAddressSearchErrorUser, resetPhoneErrorMessages } =
  userSlice.actions;
const userReducer = userSlice.reducer;

export default userReducer;
